import { css } from 'glamor'
import PropTypes from 'prop-types'
import React from 'react'
import { colors, media, text } from '@/styles'
import LegacySearchBar from './legacy/searchBar'

// Algolia Search v5 upgrade and react-instantsearch preparation
// const searchClient = algoliasearch(
//   //get ALGOLIA_APP_ID from env,
//   //get ALGOLIA_API_KEY from env,
// )

const searchFontWeight = 600

const style = {
  searchBar: css({
    position: 'relative',
    fontWeight: searchFontWeight,
    '& input': {
      fontWeight: searchFontWeight
    },
    '> div > .search-pane': {
      left: 0,
      right: 'auto',
      top: '42px',
      width: '100%',
      '& .search-name': css(text.body, {
        margin: '0 0 4px'
      }),
      '& .search-page > li': {
        border: `1px solid ${colors.gray89}`,
        borderLeft: 'none',
        display: 'none',
        fontSize: '14px',
        fontWeight: '100',
        lineHeight: '38px',
        margin: '16px 0',
        padding: 0,
        width: '40px',
        '&:first-child': {
          borderLeft: `1px solid ${colors.gray89}`
        },
        '& > a': {
          display: 'block'
        }
      }
    },
    ...media.mobile({
      width: '100%'
    })
  })
}

const SearchBar = ({ location, name, placeholder, className, ...props }) => (
  <div
    {...style.searchBar}
    className={`search-bar ${className ?? ''}`}
    {...props}
  >
    {/* Algolia Search v5 upgrade and react-instantsearch preparation */}
    {/*<InstantSearch indexName="//get ALGOLIA_INDEX from env" searchClient={searchClient}>*/}
    {/*  <SearchBox />*/}
    {/*  <Hits />*/}
    {/*</InstantSearch>*/}

    <LegacySearchBar
      location={location}
      name={name}
      placeholder={placeholder}
    />
  </div>
)

SearchBar.propTypes = {
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default SearchBar
