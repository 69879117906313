import { css } from 'glamor'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { colors, media, text } from '../styles'
import Banner from './banner'

const style = {
  promotion: css(
    {
      padding: '16px',
      background: colors.actionOrange,
      '& > a': {
        textDecoration: 'none'
      }
    },
    text.h5,
    {
      '& .time-left': {
        '& > span:first-child': {
          fontWeight: 'normal',
          lineHeight: '1.2em',
          display: 'inline-block'
        }
      }
    },
    media.desktop({
      '& .time-left': {
        verticalAlign: 'middle',
        fontSize: 'small',
        '& > span:last-child': {
          display: 'inline-block',
          background: colors.white,
          color: colors.grayDark,
          padding: '10px 10px 5px',
          margin: '0 10px',
          '& > span': {
            padding: '0 5px',
            fontSize: 'larger',
            lineHeight: '1em',
            '& > span': {
              display: 'block',
              fontWeight: 'normal',
              fontSize: 'small'
            }
          }
        }
      }
    })
  )
}

const SECOND = 1000
const MINUTE = 60
const HOUR = MINUTE * 60

const diffTime = (end) => {
  let diff = (end.getTime() - Date.now()) / SECOND
  if (diff < 0) {
    return null
  }

  const hours = Math.floor(diff / HOUR)
  diff -= hours * HOUR
  const minutes = Math.floor(diff / MINUTE)
  diff -= minutes * MINUTE
  const seconds = Math.floor(diff)

  return { hours: Math.floor(hours), minutes, seconds }
}

class PromotionBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timeLeft: diffTime(new Date(props.promotion.fields.endDate))
    }
  }

  componentDidMount() {
    this.updateTime()
    this.updateId = setInterval(this.updateTime, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.updateId)
  }

  updateTime = () => {
    this.setState({
      timeLeft: diffTime(new Date(this.props.promotion.fields.endDate))
    })
  }

  render() {
    const { fields } = this.props.promotion
    const { timeLeft } = this.state

    const content = (
      <Fragment>
        {timeLeft && fields.showCountdown ? (
          <span className="time-left">
            <span>
              Ends <br className="d-none d-lg-block" />
              In:
            </span>{' '}
            <span>
              <span>
                {timeLeft.hours}
                <span className="d-lg-none">:</span>
                <span className="d-none d-lg-block"> HRS</span>
              </span>
              <span>
                {timeLeft.minutes}
                <span className="d-lg-none">:</span>
                <span className="d-none d-lg-block"> MIN</span>
              </span>
              <span>
                {timeLeft.seconds}
                <span className="d-none d-lg-block"> SEC</span>
              </span>
            </span>
          </span>
        ) : null}
        <span className="d-none d-md-block title">{fields.title}</span>
        <span className="d-inline-block d-md-none title">
          {fields.mobileTitle}
        </span>
        {fields.discountCode && (
          <span className="discount-text">
            Use Code:{' '}
            <span className="discount-code">{fields.discountCode}</span>
          </span>
        )}
      </Fragment>
    )

    return (
      <Banner style={style.promotion}>
        {fields.link ? <a href={fields.link}>{content}</a> : content}
      </Banner>
    )
  }
}

PromotionBanner.propTypes = {
  promotion: PropTypes.object.isRequired
}

export default PromotionBanner
