import { css } from 'glamor'
import find from 'lodash/find'
import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import Link from 'react-router/lib/Link'
import { locationToCodeMapping } from '@/constants/storeNavigation'
import { reset as resetSearch, search } from '@/redux/actions/algolia'
import { canonicalizeStore } from '@/shared/canonicalize'
import Icon, { icons } from '../icon'
import { colors } from '@/styles'

const style = {
  activePage: css({
    color: '#A8A8A8'
  }),
  pageNumber: css({
    color: '#337AB7',
    fontSize: '16px',
    fontWeight: '700',
    padding: '8px 4px'
  }),
  pageEllipsis: css({
    fontSize: '16px',
    padding: '8px 4px'
  }),
  searchPane: css({
    border: '1px solid #AAAAAA',
    borderRadius: '4px',
    left: '15px',
    overflow: 'hidden',
    position: 'absolute',
    right: '15px',
    top: '40px',
    zIndex: 2
  }),
  searchResults: css({
    background: '#FFFFFF',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      borderBottom: '1px solid #e5e5e5',
      display: 'block',
      padding: '4px 12px',
      '& em': {
        fontStyle: 'normal',
        fontWeight: '700'
      },
      '&:hover': {
        textDecoration: 'none'
      },
      '& .search-name': {
        color: 'black',
        fontSize: '16px'
      },
      '& .search-details': {
        color: '#A8A8A8',
        fontSize: '13px'
      },
      '& .search-mascot': {
        color: 'black'
      }
    }
  }),
  searchIcon: css({
    cursor: 'pointer',
    fontSize: '16px',
    color: colors.grayMedium,
    margin: 0,
    padding: '10px 12px 10px 12px',
    position: 'absolute',
    right: '5px',
    top: '3px'
  }),
  searchInput: css({
    display: 'block',
    textOverflow: 'ellipsis',
    background: colors.gray97,
    border: `1px solid ${colors.gray77}`,
    borderRadius: '10px',
    color: colors.grayMedium,
    fontSize: '14px',
    lineHeight: '22px',
    margin: 0,
    padding: '10px 12px 10px 12px',
    width: '100%',
    '&:focus': {
      outline: 'none'
    }
  })
}

const SearchResult = ({ searchResult, onClick }) => {
  const { City, Mascot, _highlightResult } = searchResult

  const {
    City: { value: highlightedCity } = {},
    Mascot: { value: highlightedMascot } = {},
    SchoolName: { value: highlightedSchoolName } = {},
    State: { value: highlightedState } = {}
  } = _highlightResult

  return (
    <li key={searchResult.SchoolID} tabIndex={0}>
      <Link
        to={canonicalizeStore(searchResult)}
        {...style.searchResults}
        onClick={onClick}
        tabIndex={1}
      >
        <div
          className="search-name"
          dangerouslySetInnerHTML={{
            __html: highlightedSchoolName
          }}
        />
        <div className="search-details">
          {Mascot && (
            <span
              className="search-mascot"
              dangerouslySetInnerHTML={{
                __html: `${highlightedMascot} - `
              }}
            />
          )}
          {City && (
            <Fragment>
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightedCity
                }}
              />
              ,{' '}
            </Fragment>
          )}
          <span
            dangerouslySetInnerHTML={{
              __html: highlightedState
            }}
          />
        </div>
      </Link>
    </li>
  )
}

SearchResult.propTypes = {
  searchResult: PropTypes.object.isRequired,
  onClick: PropTypes.func
}

class SearchBar extends PureComponent {
  constructor(props) {
    super(props)

    const { searchInput: query = '', searchFocus: showResults = false } =
      process.browser ? window.__CLIENT_STATE__ : {}

    this.state = { query, showResults }

    this.el = React.createRef()
  }

  doSearch({ query, page }) {
    const {
      dispatch,
      location: { pathname },
      name
    } = this.props

    const typeMapping = find(locationToCodeMapping, (value, key) =>
      new RegExp(key).test(pathname)
    )

    dispatch(search(query, typeMapping, name, page))
  }

  search = (e) => {
    const { value: query } = e.target
    this.setState({ query })
    this.setState({ showResults: true })
    this.doSearch({ query, page: 0 })
  }

  reset = () => {
    this.props.dispatch(resetSearch())
  }

  onBlur = (e) => {
    // If lost focus because user clicked on a search result, do nothing
    if (this.el?.current?.contains(e.relatedTarget || document.activeElement)) {
      return
    }

    this.setState({ showResults: false })
  }

  onFocus = () => {
    this.setState({ showResults: true })
  }

  componentDidMount() {
    const { showResults } = this.state
    if (showResults) {
      this.el.current.querySelector('input').focus()
    }
  }

  render() {
    const { name, placeholder, search } = this.props
    const { hits = [] } = search.items
    const showResults =
      this.state.showResults &&
      search.source === name &&
      this.state.query.length !== 0 &&
      hits.length !== 0

    return (
      <div ref={this.el}>
        <div className="icon" {...style.searchIcon}>
          <Icon icon={icons.search} size="md" />
        </div>
        <input
          autoComplete="off"
          name={name}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.search}
          placeholder={placeholder}
          type="text"
          value={this.state.query || ''}
          {...style.searchInput}
        />
        <div
          className={`search-pane ${showResults ? '' : 'd-none'}`}
          {...style.searchPane}
        >
          <ul {...style.searchResults} className="search-results">
            {hits.map((searchResult) => (
              <SearchResult
                key={searchResult.SchoolID}
                searchResult={searchResult}
                onClick={this.reset}
              />
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

SearchBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  search: PropTypes.object.isRequired
}

const stateToProps = (state, props) => {
  let { search } = state

  if (process.browser) {
    const { __CLIENT_STATE__: clientState } = window
    // Prefer client state until new query is triggered
    clientState.used = clientState.used || search.loading
    if (!clientState.used) {
      search = {
        query: clientState.searchInput || '',
        source: props.name,
        items: { hits: clientState.searchResults || [] }
      }
    }
  }

  return { search }
}

export default connect(stateToProps)(SearchBar)
