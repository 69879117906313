import { css } from 'glamor'

export const fonts = {
  raleway: "Raleway, 'Helvetica Neue', Helvetica, Arial, sans-serif"
}

export const text = {
  badge: css({
    fontSize: '11px',
    fontWeight: '700',
    lineHeight: '13px',
    textTransform: 'uppercase'
  }),
  badgeSmall: css({
    fontSize: '8px',
    lineHeight: '11px',
    textAlign: 'center',
    fontStyle: 'normal'
  }),
  body: css({
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
    textTransform: 'none'
  }),
  get bodyLarge() {
    return css(text.body, {
      lineHeight: '20px'
    })
  },
  get bodyHeader() {
    return css(text.body, {
      fontWeight: '700'
    })
  },
  get bodySmall() {
    return css(text.body, {
      fontSize: '12px',
      lineHeight: '20px'
    })
  },
  get bodySmallBold() {
    return css(text.bodySmall, {
      fontWeight: '700'
    })
  },
  get button() {
    return css(text.input, {
      fontWeight: '700',
      textTransform: 'uppercase'
    })
  },
  finePrint: css({
    fontSize: '11px',
    lineHeight: '15px'
  }),
  h1: css({
    fontSize: '42px',
    fontWeight: '700',
    lineHeight: '49px',
    textTransform: 'uppercase'
  }),
  h2: css({
    fontSize: '32px',
    fontWeight: '700',
    lineHeight: '38px',
    textTransform: 'none'
  }),
  h3: css({
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '28px',
    textTransform: 'none'
  }),
  h4: css({
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '21px',
    textTransform: 'uppercase'
  }),
  h5: css({
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '16px',
    textTransform: 'uppercase'
  }),
  icon: css({
    fontSize: '18px',
    lineHeight: '18px'
  }),
  iconLarge: css({
    fontSize: '20px',
    lineHeight: '20px'
  }),
  small: css({
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
    textTransform: 'none'
  }),
  input: css({
    fontSize: '14px',
    lineHeight: '24px'
  }),
  inputLabel: css({
    fontSize: '11px',
    lineHeight: '12px'
  }),
  menu: css({
    fontSize: '14px',
    textTransform: 'uppercase'
  })
}
