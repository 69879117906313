import { css } from 'glamor'
import isArray from 'lodash/isArray'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { connect } from 'react-redux'
import Link from 'react-router/lib/Link'
import { storeTypes } from '../constants/storeNavigation'
import psLogo from '../images/ps_logo_white.svg'
import { colors, common as commonStyles, media, text } from '../styles'
import Button from './button'
import Icon, { icons } from './icon'

const style = {
  footerWrapper: css({
    marginTop: '45px',
    backgroundColor: colors.gray95
  }),
  legal: css(text.body, {
    margin: '30px 0 0'
  }),
  newsletter: css({
    padding: '45px 0',
    ...media.xs({
      padding: '30px 0'
    })
  }),
  title: css(text.h4, {
    margin: '0 0 10px',
    '& > strong': {
      color: colors.actionOrange
    },
    ...media.mobile({
      paddingRight: '25px'
    })
  }),
  noMarginNoPadding: css({ padding: 0, margin: 0 }),
  nav: css({
    backgroundColor: colors.grayDark,
    color: colors.white,
    padding: '15px 0',
    '& .hidden-xs h5': {
      fontSize: '12px',
      marginBottom: '5px'
    },
    '& a': {
      color: colors.headingText
    },
    '& .card': {
      margin: 0,
      border: 'none',
      borderRadius: 0,
      backgroundColor: 'initial',
      boxShadow: 'none',
      '& .card-link': {
        cursor: 'pointer',
        padding: '15px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        },
        '& h5': {
          color: colors.white,
          margin: 0
        },
        '& .fa': {
          color: colors.headingText
        }
      },
      '& .card-body': {
        margin: '-10px 0',
        padding: '0 0 0 15px'
      }
    },
    ...media.sm({
      padding: '35px 0'
    }),
    ...media.desktop({
      padding: '35px 0'
    })
  }),
  navList: css({
    lineHeight: '20px',
    '& li > a': {
      textDecoration: 'none'
    },
    ...media.xs({
      lineHeight: '40px'
    }),
    ...media.sm({
      lineHeight: '40px'
    }),
    color: colors.white
  }),
  logo: css({
    marginTop: '25px'
  }),
  disclaimer: css({
    margin: '10px 0 0',
    ...media.desktop({
      lineHeight: '41px',
      margin: 0
    })
  }),
  bottomAlign: css({
    textAlign: 'center',
    ...media.desktop({
      textAlign: 'left'
    })
  }),
  tou: css({
    whiteSpace: 'nowrap',
    '& a': {
      textDecoration: 'none'
    }
  }),
  text: {
    discountText: css({
      color: colors.primaryBG,
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: 0,
      ...media.mobile({
        textAlign: 'center',
        width: '100%'
      })
    }),
    subTitleText: css({
      ...media.mobile({
        textAlign: 'center',
        width: '100%'
      })
    })
  },
  footerHeading: css({
    marginBottom: '5px',
    marginTop: 0,
    ...media.mobile({
      textAlign: 'center',
      width: '100%'
    })
  }),
  subscribeButton: css({
    ...media.mobile({
      textAlign: 'center'
    }),
    textAlign: 'right'
  })
}

const Signup = () => {
  function subscribeSMS() {
    window.open(
      'https://prepsportswear.attn.tv/p/AqS/landing-page?utm_campaign=230517_prep_sale&utm_content=230517_prep_sale&utm_medium=email_action&utm_source=customer.io',
      '_blank'
    )
  }

  return (
    <div>
      <Container>
        <Row {...style.newsletter}>
          <Col xs={12} lg={6}>
            <Container className="d-none d-md-block">
              <Row>
                <h4 {...style.footerHeading}>
                  <span>Sign up & save up to: </span>
                  <span {...style.text.subTitleText}>30% off</span>
                </h4>
              </Row>
              <Row>
                <span {...style.text.subTitleText}>
                  Be the first to hear about new products & deals.
                </span>
              </Row>
            </Container>
            <Container fluid className="d-md-none">
              <Row>
                <h4 {...style.footerHeading}>Sign up & save up to:</h4>
                <h2 {...style.text.discountText}>30% off</h2>
              </Row>
              <Row>
                <p {...style.text.subTitleText}>
                  Be the first to hear about new products & deals.
                </p>
              </Row>
            </Container>
          </Col>
          <Col xs={12} lg={6} {...style.subscribeButton}>
            <Button onClick={subscribeSMS}>Subscribe</Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

Signup.propTypes = {
  onClose: PropTypes.func,
  onFocus: PropTypes.func,
  styles: PropTypes.object
}

class FooterNav extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  renderPanel(key, title, body) {
    const expanded = !!this.state[key]

    return (
      <Fragment>
        <div className="d-none d-md-block">
          <h5>{title}</h5>
          {body}
        </div>
        <div className="d-block d-md-none" {...style.footerSection}>
          <Card>
            <Card.Link onClick={() => this.setState({ [key]: !expanded })}>
              <h5>{title}</h5>
              <Icon icon={expanded ? icons.minus : icons.plus} />
            </Card.Link>
            <Collapse in={expanded}>
              <Card.Body>{body}</Card.Body>
            </Collapse>
          </Card>
        </div>
      </Fragment>
    )
  }

  renderList(items) {
    return (
      <ul {...commonStyles.unstyled} {...style.navList}>
        {items.map(({ href, title, anchor = false, ...rest }, index) => (
          <li key={index}>
            {href.indexOf('http') === 0 || anchor ? (
              <a href={href} {...rest}>
                {title}
              </a>
            ) : (
              <Link to={href} {...rest}>
                {title}
              </Link>
            )}
          </li>
        ))}
      </ul>
    )
  }

  startChart = (e) => {
    e.preventDefault()

    // https://ent1portal.comm100.com/LiveChatFunc/doc/CustomJS/index.html#/?id=open-chat-window
    window.Comm100API && window.Comm100API.do('livechat.button.click')
  }

  render() {
    const {
      location: { query, pathname },
      storeDetails
    } = this.props
    const isCustomerService = pathname.indexOf('customerservice') > 0

    const helpItems = this.renderList([
      { href: '/account/track', title: 'Track Order' },
      {
        href: 'https://dash12.comm100.io/kb/10002809/9a734bf5-42db-43ac-8648-f5ba28ccd856',
        title: 'FAQ'
      },
      {
        href: '/site/customer-service/contact-us',
        title: 'Contact Us'
      },
      { href: '/cart', title: 'Shopping Cart' }
      // Remove until AuthCookie issue is resolved
      // {href: '/account/teamstores', title: 'Team Stores'}
    ])

    const storeItems = [
      { anchor: true, href: '/store/addstore', title: 'New Store Request' }
    ]

    if (query.schoolid && storeDetails) {
      const editUrl =
        storeDetails.StoreType === storeTypes.LITTLE_LEAGUE ||
        storeDetails.StoreType === storeTypes.TEAM
          ? `/store/claimstore?storeid=${query.schoolid}`
          : `/store/editstore?storeid=${query.schoolid || query.storeid || ''}`

      storeItems.push({
        anchor: true,
        href: editUrl,
        title: 'Store Correction'
      })
    }

    const addStoreItems = this.renderList(storeItems)

    const programsItems = this.renderList([
      { href: '/site/programs/affiliate-program', title: 'Affiliate Program' }
    ])

    const companyItems = this.renderList([
      { href: '/site/company/about-us', title: 'About Us' },
      { href: '/reviews', title: 'Reviews' },
      { href: '/site/company/careers', title: 'Careers' },
      {
        href: '/site/company/corporate-responsibility',
        title: ' Corporate Responsibility '
      },
      {
        href: '/site/company/termsofuse',
        title: ' Terms of Use'
      },
      {
        href: '/site/company/california-transparency',
        title: ' California Disclosure'
      }
    ])

    return (
      <footer id="footer-content" {...style.footerWrapper}>
        {!isCustomerService ? (
          <Fragment>
            <Signup />
          </Fragment>
        ) : null}
        <div {...style.nav}>
          <Container>
            <Row>
              <Col xs={12} md={3}>
                {this.renderPanel(
                  'contact',
                  'Contact Us',
                  <ul {...commonStyles.unstyled} {...style.navList}>
                    <li>Monday - Friday</li>
                    <li>6am - 7pm PST</li>
                    <li>
                      <a
                        href=""
                        onClick={this.startChart}
                        {...css({ textDecoration: 'underline' })}
                      >
                        Direct Message
                      </a>
                    </li>
                  </ul>
                )}
              </Col>
              <Col xs={12} md={3}>
                {this.renderPanel('help', 'Help', helpItems)}
              </Col>
              <Col xs={12} md={3}>
                {this.renderPanel('company', 'Company', companyItems)}
              </Col>
              <Col xs={12} md={3}>
                {this.renderPanel('add_stores', 'Add Stores', addStoreItems)}
                {this.renderPanel('programs', 'Programs', programsItems)}
              </Col>
            </Row>
            <Row {...css(style.logo, style.bottomAlign)}>
              <Col xs={12} lg={3}>
                <img className="footer-logo" src={psLogo} alt="ps logo" />
              </Col>
              <Col xs={12} lg={9}>
                <p {...style.disclaimer}>
                  <small>
                    © {new Date().getFullYear()} Sportswear Inc. All Rights
                    Reserved.{' '}
                    <span {...style.tou}>
                      <Link to="/site/company/termsofuse">Terms of Use</Link> |{' '}
                      <Link to="/site/company/privacypolicy">
                        Privacy Policy
                      </Link>
                    </span>
                  </small>
                </p>
              </Col>
            </Row>
            {storeDetails &&
              !storeDetails.LicensedStoreView &&
              !storeDetails.Branded && <FooterCopy store={storeDetails} />}
          </Container>
        </div>
      </footer>
    )
  }
}

FooterNav.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  storeDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

const FooterCopy = ({ store }) => {
  const {
    LongAbbreviation: longAbbreviation,
    SchoolNameForPrint: printName,
    Mascot: mascot = ''
  } = store

  return (
    <Row>
      <Col xs={12}>
        <p {...style.legal}>
          This store is not affiliated with, sponsored or endorsed by{' '}
          {printName} {mascot}. You have the ability to pick from an assortment
          of product colors. Design colors may not be identical to that of
          official licensed merchandise. Prep Sportswear is not affiliated with
          the {printName} {mascot} Bookstore or the {longAbbreviation}{' '}
          Bookstore. All products in this store are exclusively produced and
          fulfilled by Prep Sportswear.
        </p>
      </Col>
    </Row>
  )
}

FooterCopy.propTypes = {
  store: PropTypes.object.isRequired
}

const stateToProps = (state) => {
  let {
    stores: { items: storeDetails }
  } = state
  if (storeDetails && isArray(storeDetails)) {
    storeDetails = storeDetails[0]
  }

  return {
    storeDetails
  }
}

export default connect(stateToProps)(FooterNav)
