export const urlRoutes = {
  storeMain: [
    'adventure/*',
    'college/*',
    'destination/*',
    'fantasy_sports/*',
    'golf/*',
    'government/*',
    'greek/*',
    'littleleague/*',
    'military/*',
    'prosports/*',
    'races/*',
    'school/*',
    'team/*',
    'vintageteams/*'
  ],

  channelMain: [
    'school_directory(.aspx)',
    'school_directory/:country',
    'college_directory(.aspx)',
    'college_directory/:country',
    'prosports_directory(.aspx)',
    'prosports_directory/:sport',
    'prosports_directory/:sport/:team',
    'vintageteams_directory(.aspx)',
    'vintageteams_directory/:sport',
    'golf_directory(.aspx)',
    'golf_directory/:country',
    'greek_directory(.aspx)',
    'greek_directory/:type',
    'littleleague_directory(.aspx)',
    'littleleague_directory/:country',
    // 'military_directory(.aspx)',
    // 'military_directory/:branch',
    'races_directory(.aspx)',
    'races_directory/:sport',
    'destination_directory(.aspx)',
    'destination_directory/:type',
    'adventure_directory(.aspx)',
    'adventure_directory/:parkType',
    'government_directory(.aspx)',
    'government_directory/:type'
  ],

  channelList: [
    'school_directory/:country/:state',
    'school_directory/:country/:state/:city',
    'college_directory/:country/:state',
    'college_directory/:country/:state/:city',
    'prosports_directory/:sport/:team/:league',
    'vintageteams_directory/:sport/:team',
    'golf_directory/:country/:state',
    'golf_directory/:country/:state/:city',
    'races_directory/:sport/:state',
    'races_directory/:sport/:state/:city',
    'destination_directory/:country/:state',
    'destination_directory/:country/:state/:city',
    'adventure_directory/:country/:state',
    'adventure_directory/:country/:state/:city',
    'government_directory/:country/:state',
    'government_directory/:country/:state/:city',
    'littleleague_directory/:country/:state',
    'littleleague_directory/:country/:state/:city'
  ],

  shippingBilling: ['checkout/shippingandbilling'],

  confirm: ['checkout/confirm/:orderId'],

  fanthread: ['fanthread'],

  cart: ['cart'],

  genericProduct: ['review'],

  reviews: ['reviews'],

  product: ['product', 'product/*', '(**/)product/*'],

  productList: ['(**/)productlist(.aspx)'],

  addStore: ['store/addstore'],

  requestAddStore: ['store/storeadded'],

  claimStore: ['store/claimstore'],

  storeDashboard: ['store/dashboard'],

  editStore: ['store/editstore'],

  requestEditStore: ['store/storeedited/:storeid'],

  teamStore: ['channel/manageteam'],
  teamStoreAdd: ['channel/manageteam/add'],

  customerService: [
    'site/company/*',
    'site/customer-service/*',
    'site/programs/*'
  ],

  promoPage: ['site/promo/*'],

  landingPage: ['site/**'],

  track: ['account/track'],

  order: ['account/order/:orderId'],

  designAdmin: ['admin/design/upload'],

  designQueue: ['admin/design/queue'],

  fiveXx: ['5xx'],

  offline: ['offline'],

  fourOhFour: ['404', '**'],

  stripeCheckout: ['stripe-checkout']
}
