import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import url from 'url-parse'
import { navigationNodes } from '../constants/storeNavigation'

const spaceRe = /\s+/g
const disallowedChars = /[^\w\d\-]/g
const andRe = /[&]/g

const join = (parts, delimiter = ' ') => parts.filter((p) => p).join(delimiter)

export const slugify = (word, separator = '-') =>
  (word || '')
    .trim()
    .toLowerCase()
    .replace(andRe, 'and')
    .replace(spaceRe, separator)
    .replace(disallowedChars, '')

const formatPath = (segments) =>
  `/${segments.filter((segment) => segment !== undefined).join('/')}`

const catalogName = ({
  StoreType,
  SchoolType,
  SchoolName,
  TeamName,
  Sport,
  LeagueName
}) => {
  if (StoreType === 't') {
    return SchoolType === 45 // team2
      ? join([SchoolName, Sport])
      : join([LeagueName, SchoolName || TeamName, Sport])
  }

  return SchoolName
}

const defaultPath = (store, prefix, mascot = true) =>
  formatPath([
    prefix,
    slugify(store.CountryCode.toLowerCase()),
    slugify(store.State),
    slugify(store.City),
    slugify(join([catalogName(store), mascot ? store.Mascot : null]))
  ])

const adventurePath = (store) =>
  formatPath([
    'adventure',
    slugify(store.CatalogKey),
    slugify(store.State),
    slugify(catalogName(store))
  ])

const destinationPath = (store) =>
  store.CatalogKey === 'Countries' ||
  store.CatalogKey === 'States' ||
  store.CatalogKey === 'Counties'
    ? formatPath([
        'desination',
        slugify(store.CatalogKey),
        slugify(catalogName(store))
      ])
    : formatPath([
        'destination',
        'cities',
        slugify(join([catalogName(store), store.State]))
      ])

const fantasyPath = (store) =>
  formatPath(['fantasy', slugify(store.Sport), slugify(catalogName(store))])

const greekPath = (store) =>
  formatPath([
    'greek',
    slugify(store.CountryCode.toLowerCase()),
    slugify(store.CatalogKey),
    slugify(catalogName(store))
  ])

const governmentPath = (store) =>
  formatPath([
    'government',
    slugify(store.CountryCode.toLowerCase()),
    slugify(store.CatalogKey),
    slugify(store.State),
    slugify(store.City),
    slugify(catalogName(store))
  ])

const militaryPath = (store) =>
  formatPath([
    'military',
    slugify(store.CountryCode.toLowerCase()),
    slugify(store.CatalogKey),
    slugify(catalogName(store))
  ])

const proSportsPath = (store) =>
  formatPath([
    'prosports',
    slugify(store.CatalogKey),
    slugify(catalogName(store))
  ])

const vintagePath = (store) =>
  formatPath([
    'vintageteams',
    slugify(store.CatalogKey),
    slugify(store.State),
    slugify(catalogName(store))
  ])

// Map StoreType to path generation function
const PATH_FNS = {
  a: adventurePath,
  c: (store) => defaultPath(store, 'college'),
  d: destinationPath,
  e: (store) => defaultPath(store, 'races'),
  f: governmentPath,
  g: greekPath,
  l: (store) => defaultPath(store, 'golf'),
  k: (store) => defaultPath(store, 'littleleague', false),
  m: militaryPath,
  o: fantasyPath,
  s: (store) => defaultPath(store, 'school'),
  t: (store) => defaultPath(store, 'team', false),
  v: vintagePath,
  w: proSportsPath
}

const sortObject = (obj) => {
  const sorted = {}
  Object.keys(obj)
    .sort()
    .forEach((key) => (sorted[key] = obj[key]))

  return sorted
}

export const canonicalizeStore = (store, path = [], query) => {
  const fn = PATH_FNS[store?.StoreCode.toLowerCase()]

  if (!fn) {
    return null
  }

  // Note: Pass empty location so that server and client behavior is the same.
  // Note: `path.map(slugify)` is not used because it would result in the
  //   loop index being used as the separator.
  const u = new url(
    path.length
      ? `${fn(store)}/${path.map((p) => slugify(p)).join('/')}`
      : fn(store),
    {}
  )
  u.set(
    'query',
    omitBy(sortObject({ ...query, schoolid: store.StoreID }), isNil)
  )

  return u.href
}

export const canonicalizeProduct = (store, product, query) =>
  canonicalizeStore(
    store,
    ['product', sanitizeProduct(store, product.ProductName || product.Name)],
    {
      ...query,
      productid: product.ProductID
    }
  )

export const canonicalizeCategory = (store, category, query) =>
  canonicalizeStore(store, ['productlist'], { ...query, category })

export const sanitizeProduct = (store, name) => {
  return name
    .replace(/<.+?>/g, '')
    .replace(new RegExp(store?.StoreName || '', 'i'), '')
    .trim()
}

export const canonicalizeChannelList = (channels) => {
  const u = new url(
    formatPath([
      `${channels.channel.value}_directory`,
      channels[navigationNodes.COUNTRY]
        ? channels[navigationNodes.COUNTRY].value
        : undefined,
      channels[navigationNodes.STATE_LIST]
        ? channels[navigationNodes.STATE_LIST].value
        : undefined,
      channels[navigationNodes.CITY_LIST]
        ? channels[navigationNodes.CITY_LIST].value
        : undefined
    ])
  )

  // Replaced u.href with u.pathname to resolve a client-side console error that hrefs should not have the hostname
  return u.pathname
}
