import { css } from 'glamor'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container'
import NavItem from 'react-bootstrap/NavItem'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import Link from 'react-router/lib/Link'
import { queryTypes } from '@/constants/cart'
import psLogo from '!/images/ps_logo.svg'
import { fetchCart } from '@/redux/actions/cart'
import { fetchChannels } from '@/redux/actions/channel'
import { getBanner, getPromotion } from '@/redux/actions/contentful'
import { colors, media, palettes, text } from '@/styles'
import Banner from './banner'
import Icon, { icons } from './icon'
import PromotionBanner from './promotionBanner'
import Dropdown from 'react-bootstrap/Dropdown'
import SearchBar from '@/components/searchBar'

const headerFontWeight = 600

const style = {
  navbar: css({
    border: 'none',
    borderBottom: `1px solid ${colors.gray77}`,
    backgroundColor: colors.white,
    borderRadius: 0,
    margin: 0,
    padding: 0,
    '& > .container-fluid': {
      display: 'flex',
      maxWidth: '1680px',
      maxHeight: '90px'
    },
    ...media.mobile({
      border: 'none'
    })
  }),
  leftNav: css({
    flex: 'none !important',
    fontWeight: headerFontWeight
  }),
  rightNav: css({
    justifyContent: 'flex-end',
    fontWeight: headerFontWeight
  }),
  mobileSearchBarContainer: css({
    paddingBottom: '20px',
    borderBottom: `1px solid ${colors.gray77}`
  }),
  break: css({
    flexBasis: '100%',
    height: 0
  }),
  searchBar: css({
    flex: '1 1 auto',
    width: '100%',
    ...media.desktop({
      marginLeft: '18px'
    })
  }),
  menu: css(
    {
      width: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      // Affects the vertical position of the navbar links
      '& > .navbar-nav': {
        flex: '1 1 auto',
        position: 'relative',
        alignItems: 'center'
      }
    },
    // Give the navbar brand more space in smaller viewports
    media.mobile({
      '& > .navbar-nav': {
        padding: 0
      },
      '& > .navbar-brand': {
        flex: '1 1 auto',
        textAlign: 'center',
        margin: '0 15px',
        padding: '24px 0',
        // Make the navbar brand take up as much space as it can in the smallest viewport, so it's centered in the header
        ...media.mobile({
          margin: '0',
          width: '100%',
          padding: '18px 0 18px 0'
        }),
        ...media.md({
          width: 'auto'
        })
      }
    }),
    media.desktop({
      '& > .navbar-brand': {
        margin: '0'
      }
    }),
    {
      '& > .navbar-brand > img': {
        ...media.desktop({
          width: 'auto',
          height: '40px'
        })
      }
    }
  ),
  navbarItem: css({
    margin: '0 18px',
    fontWeight: headerFontWeight,
    '& > a': {
      textDecoration: 'none',
      color: `${colors.defaultText} !important`,
      '&:hover:after, &.active-store-link:after': {
        content: '""',
        display: 'block',
        height: '4px',
        background: colors.actionOrange,
        position: 'relative',
        top: '10px'
      }
    }
  }),
  mobileMenu: css({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: colors.gray97,
    zIndex: 2,
    padding: '15px',
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: '45px',
    fontSize: '18px',
    '& .fa': {
      color: colors.gray46
    },
    '& a': {
      color: colors.defaultText
    },
    '& > .close-menu': {
      position: 'absolute',
      right: '15px'
    },
    '& > div:last-child': {
      color: colors.white,
      background: colors.gray20,
      margin: '0 -15px',
      padding: '15px 0',
      '& a': {
        color: colors.white
      }
    }
  }),
  dropdown: css({
    fontWeight: headerFontWeight,
    '&#headernav-help > button': {
      fontSize: '12px'
    },
    '& > a': {
      color: colors.defaultText
    },
    '& > .dropdown-toggle:hover': {
      boxShadow: 'none',
      background: colors.gray95
    },
    '&.show > .dropdown-toggle:focus': {
      boxShadow: 'none',
      background: colors.gray95
    },
    '& > .dropdown-toggle': {
      fontWeight: headerFontWeight,
      padding: '30px 15px',
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
      background: colors.white,
      textShadow: 'none',
      color: colors.defaultText,
      lineHeight: '20px',
      '&::after': {
        verticalAlign: '2px'
      }
    },
    '&.show > .dropdown-menu': {
      border: 'none',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: 'none',
      background: colors.gray95,
      margin: 0
    },
    '& > .dropdown-menu > li': {
      padding: '10px 20px',
      textTransform: 'uppercase'
    },
    '& > .dropdown-menu > li > a': {
      textDecoration: 'none',
      color: 'black'
    },
    '&.show > .dropdown-toggle > .fa': {
      color: palettes.primary.base
    }
  }),
  cart: css(
    text.body,
    {
      fontWeight: headerFontWeight,
      whiteSpace: 'nowrap',
      fontSize: '12px',
      '& > div': {
        position: 'relative',
        display: 'inline-block',
        marginRight: '5px'
      }
    },
    media.mobile({
      // Cart icon size
      '& .fa': {
        fontSize: '17px',
        position: 'relative'
      }
    }),
    media.xs({
      '& > div': {
        marginRight: 0
      }
    })
  ),
  toggleMenu: css(
    text.body,
    {
      cursor: 'pointer',
      position: 'relative',
      display: 'inline-block'
    },
    media.mobile({
      margin: '0 18px',
      // Toggle icon (hamburger) size
      '> .fa': {
        position: 'relative',
        top: '0px'
      }
    })
  ),
  cartItems: css({
    background: colors.actionOrange,
    border: `1px solid ${colors.white}`,
    borderRadius: '50%',
    color: colors.white,
    height: '14px',
    left: '9px',
    lineHeight: '14px',
    position: 'absolute',
    top: '-2px',
    width: '14px',
    ...media.mobile({
      left: '18px'
    })
  })
}

const helpItems = [
  { to: '/account/track', name: 'Track Orders' },
  {
    href: 'https://dash12.comm100.io/kb/10002809/9a734bf5-42db-43ac-8648-f5ba28ccd856',
    name: 'FAQ'
  },
  {
    href: 'https://dash12.comm100.io/kb/10002809/9a734bf5-42db-43ac-8648-f5ba28ccd856/a/2adb8ffe-62b1-4fc7-a4c2-f31fb0f974b7/What-is-your-return-policy',
    name: 'Returns'
  },
  { to: '/site/customer-service/contact-us', name: 'Contact Us' }
]

/**
 * Display class for navbar item
 * @param index
 * @returns {undefined|string}
 */
const displayClassForNavbarItem = (index) => {
  if (index < 1) return undefined
  if (index < 2) return 'd-none d-lg-block'
  if (index < 3) return 'd-none d-xl-block'
  if (index < 4) return 'd-none d-xxl-block'

  return 'd-none'
}

/**
 * Display class for more menu item
 * @param index
 * @returns {undefined|string}
 */
const displayClassForMoreMenuItem = (index) => {
  if (index < 1) return 'd-none'
  if (index < 2) return 'd-lg-none'
  if (index < 3) return 'd-xl-none'
  if (index < 4) return 'd-xxl-none'

  return undefined
}

const CartLink = ({ cart }) => {
  let count = 0
  if (cart && cart.CartLineItemCount) {
    count = cart.CartLineItemCount
  }
  return (
    <Link to="/cart" {...style.cart}>
      <div>
        <Icon icon={icons.shoppingCart} size="lg" />
        {count ? (
          <i {...css(text.badgeSmall, style.cartItems)}>{count}</i>
        ) : null}
      </div>

      <span className="d-none d-lg-inline">Cart</span>
      <span className="sr-only">Shopping Cart</span>
    </Link>
  )
}

CartLink.propTypes = {
  cart: PropTypes.object
}

const ChannelLink = ({
  channel: { anchor, Name, Href, Key },
  className,
  ...props
}) => {
  const url = Href || `/${Key}_directory`
  return (
    <li className={className} {...props}>
      {anchor ? (
        <a href={url}>{Name}</a>
      ) : (
        <Link activeClassName="active-store-link" to={url}>
          {Name}
        </Link>
      )}
    </li>
  )
}

ChannelLink.propTypes = {
  className: PropTypes.string,
  channel: PropTypes.shape({
    anchor: PropTypes.bool,
    Href: PropTypes.string,
    Name: PropTypes.string.isRequired,
    Key: PropTypes.string.isRequired
  }).isRequired
}

class DropdownMenu extends Component {
  render() {
    const { title, items, ...rest } = this.props

    return (
      <Dropdown {...rest} {...style.dropdown}>
        <Dropdown.Toggle>{title}</Dropdown.Toggle>
        <Dropdown.Menu id={`${rest.id}-menu`} renderOnMount>
          {items}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

DropdownMenu.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array
}

DropdownMenu.defaultProps = {
  items: []
}

class HeaderNav extends Component {
  constructor(props) {
    super(props)

    const {
      mobileMenu = false,
      moreIndex = 0,
      moreDropdown = false,
      moreLeft = 0,
      helpMenu = false
    } = process.browser ? window.__CLIENT_STATE__ : {}

    this.state = {
      mobileMenu,
      moreIndex,
      moreLeft,
      moreDropdown,
      helpMenu
    }
  }

  static initialize({ dispatch, routes }) {
    // Do not fetch contentful content on error pages. We do this because they
    // are generated at build time and served statically.
    const re = /^(404|5xx|\*\*)$/
    if (routes.find((r) => re.test(r.path))) {
      return dispatch(fetchChannels())
    }

    return Promise.all([
      dispatch(fetchChannels()),
      dispatch(fetchCart(true, queryTypes.CART_COUNT)),
      dispatch(getBanner()),
      dispatch(getPromotion())
    ])
  }

  toggleMobileMenu = () => {
    this.setState((state) => ({ mobileMenu: !state.mobileMenu }))
  }

  renderMobileMenu() {
    const { channels } = this.props
    const { mobileMenu } = this.state

    return (
      <Fragment>
        <Helmet>
          <body {...css({ position: mobileMenu ? 'relative' : 'static' })} />
          <style>{`#body-content, #footer-content { display: ${
            mobileMenu ? 'none' : 'block'
          }; }`}</style>
        </Helmet>
        <div
          {...style.mobileMenu}
          className={'mobile-menu-modal' + (mobileMenu ? '' : ' d-none')}
        >
          <div onClick={this.toggleMobileMenu} className="close-menu">
            <Icon icon={icons.times} size="lg" />
          </div>
          <ul className="list-unstyled">
            {channels.map((channel) => (
              <ChannelLink
                key={channel.Key}
                channel={channel}
                onClick={this.toggleMobileMenu}
              />
            ))}
          </ul>
          <div>
            <h4>Help</h4>
            <ul className="list-unstyled">
              {helpItems.map(({ href, to, name }, index) => (
                <li key={index}>
                  {to ? (
                    <Link to={to} onClick={this.toggleMobileMenu}>
                      {name}
                    </Link>
                  ) : (
                    <a href={href}>{name}</a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Fragment>
    )
  }

  render() {
    const { banner, cart, promotion, channels } = this.props

    return (
      <Fragment>
        {banner && (
          <Banner style={{ background: banner.fields.background }}>
            {banner.fields.text}
          </Banner>
        )}
        {promotion && <PromotionBanner promotion={promotion} />}
        {this.renderMobileMenu()}

        <Navbar {...style.navbar}>
          <Container fluid {...css(text.menu, style.menu)}>
            <NavItem
              aria-label={'Toggle Menu'}
              onClick={this.toggleMobileMenu}
              className="d-lg-none more-menu"
              {...style.toggleMenu}
            >
              <Icon icon={icons.bars} size="2x" />
            </NavItem>
            <Navbar.Brand href="/">
              <img src={psLogo} alt="Prep Sportswear Logo" />
            </Navbar.Brand>
            <Nav className="d-none d-lg-flex" {...style.leftNav}>
              {channels.map((channel, index) => (
                <ChannelLink
                  key={channel.Key}
                  channel={channel}
                  className={displayClassForNavbarItem(index)}
                  {...style.navbarItem}
                />
              ))}

              <DropdownMenu
                title="MORE"
                items={channels.map((channel, index) => (
                  <ChannelLink
                    key={channel.Key}
                    channel={channel}
                    className={displayClassForMoreMenuItem(index)}
                  />
                ))}
                id="headernav-more"
              />
            </Nav>
            <SearchBar
              // Using the root site pathname
              // to give us the same search results as if we were searching from the root of the site,
              // no matter what page we are on.
              location={{ pathname: 'headersearch' }}
              className="d-none d-md-block"
              name="headerDesktopSearchbar"
              placeholder="Search for schools, colleges, and more..."
              {...style.searchBar}
            />
            <Nav {...style.rightNav}>
              <div className="d-none d-md-block">
                <DropdownMenu
                  title="Help"
                  items={helpItems.map(({ href, to, name }, index) => (
                    <li key={index}>
                      {to ? (
                        <Link to={to}>{name}</Link>
                      ) : (
                        <a href={href}>{name}</a>
                      )}
                    </li>
                  ))}
                  id="headernav-help"
                />
              </div>
              <li {...style.navbarItem}>
                <CartLink cart={cart} />
              </li>
            </Nav>
          </Container>
        </Navbar>
        <Container
          fluid
          {...style.mobileSearchBarContainer}
          className="d-block d-md-none"
        >
          <SearchBar
            // Using the root site pathname
            // to give us the same search results as if we were searching from the root of the site,
            // no matter what page we are on.
            location={{ pathname: '/' }}
            name="headerMobileSearchbar"
            placeholder="Search for schools, colleges, and more..."
            {...style.searchBar}
          />
        </Container>
      </Fragment>
    )
  }
}

const stateToProps = (state) => {
  const {
    cart: {
      items: [cart]
    },
    channels: {
      channels: { items: channels }
    },
    contentful_entries: { banners, promotions, items }
  } = state

  const findByDate = (ids, date) =>
    ids
      .map((id) => items[id])
      .find(
        (item) =>
          date > new Date(item.fields.startDate).getTime() &&
          date < new Date(item.fields.endDate).getTime()
      )

  const date = Date.now()
  const banner = findByDate(banners, date)
  const promotion = findByDate(promotions, date)

  return {
    banner,
    promotion,
    cart,
    channels: channels
      .concat([
        {
          Name: 'Create Team Store',
          Key: 'team',
          Href: '/channel/manageteam',
          anchor: true, // Indicates it should be a page refresh
          SortOrder: 5.5, // channels returned by the API use integers
          isCreateTeamStore: true
        }
      ])
      .sort((first, second) => first.SortOrder - second.SortOrder)
  }
}

HeaderNav.propTypes = {
  banner: PropTypes.object,
  cart: PropTypes.object,
  channels: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  promotion: PropTypes.object
}

export default connect(stateToProps)(HeaderNav)
